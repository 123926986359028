// Material Design Icons https://materialdesignicons.com/ | Austin Andrews

function GateAndIcon(props) {
  const { current_operator } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      fill={current_operator === 'AND' ? '#303F9F' : 'currentColor'} 
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M2 4v16h12a8 8 0 008-8 8 8 0 00-8-8H2m2 2h10a6 6 0 016 6 6 6 0 01-6 6H4V6z" />
    </svg>
  );
}

function GateOrIcon(props) {
  const { current_operator } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      fill={current_operator === 'OR' ? '#303F9F' : 'currentColor'} 
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M2 4c3 6 3 10 0 16h6c5 0 11-4 14-8-3-4-9-8-14-8H2m3 2h3c3.5 0 8.3 3 11.3 6-3 3-7.8 6-11.3 6H5c1.4-4.1 1.4-7.9 0-12z" />
    </svg>
  );
}

function GateNandIcon(props) {
  const { current_operator } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      fill={current_operator === 'NAND' ? '#303F9F' : 'currentColor'} 
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M2 4v16h8c3.43 0 6.5-2.16 7.6-5.4.4.2.9.4 1.4.4a3 3 0 003-3 3 3 0 00-3-3c-.5 0-.97.15-1.4.4C16.5 6.16 13.43 4 10 4H2m2 2h6a6 6 0 016 6 6 6 0 01-6 6H4V6m15 5c.5 0 1 .5 1 1s-.5 1-1 1a1 1 0 01-1-1c0-.5.5-1 1-1z" />
    </svg>
  );
}

function GateNorIcon(props) {
  const { current_operator } = props;
  return (
    <svg
      viewBox="0 0 24 24"
      fill={current_operator === 'NOR' ? '#303F9F' : 'currentColor'} 
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M2 4c3 6 3 10 0 16h3c4.4 0 8-2.3 11.6-6.3.55.8 1.44 1.3 2.4 1.3a3 3 0 003-3 3 3 0 00-3-3c-.96 0-1.85.5-2.4 1.3C13 6.3 9.4 4 5 4H2m3 2c3.8 0 7 2.1 10.3 6C12 15.9 8.8 18 5 18c1.5-4 1.5-8 0-12m14 5c.5 0 1 .5 1 1s-.5 1-1 1a1 1 0 01-1-1c0-.5.5-1 1-1z" />
    </svg>
  );
}

export { GateAndIcon, GateOrIcon, GateNandIcon, GateNorIcon }
