// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: Gerard Lozano, Pau Vilella.

// handlers.js file: This file contains the logic that parses the data with the cursor from JSON to excel.

import { firstSheet, secondSheet, workbook } from './excel'
import { cursor as firstCursor } from "./firstSheet"

// in this file we write the components to the excell in the format that we want

let borderStyles = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" }
};

let METRICS = [];
let NETWORKS=[];
let nNetworks=0;
let Width=25;

function backgroundColor(row, col, color) {
    row.getCell(col).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: color }
    };
}

function headerStyle(row, col, color) {
    backgroundColor(row, col, color);
    row.getCell(col).border = borderStyles; //border styles
    row.getCell(col).font = { bold: true } //bold styles
    row.getCell(col).alignment = { horizontal: 'center' }; //aligment
}

function initializeFirstSheet(cursor) {
    const row = firstSheet.getRow(cursor.row);
    cursor.row++;
    const col = cursor.column;
    return { "row": row, "cursor": cursor, "col": col }
}



export function handleSimpleAttribute(element, cursor) {
    const initObject = initializeFirstSheet(cursor);
    const row = initObject.row;
    let col = initObject.col;
    cursor = initObject.cursor;
    firstSheet.getColumn(col).width = 16;//col width
    backgroundColor(row, col, 'C7C7C7');
    row.getCell(col).value = element.key;
    row.getCell(col).border = borderStyles; //border styles
    row.getCell(col).font = { bold: true } //bold styles
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    firstSheet.getColumn(col).width = Width; //col width
    row.getCell(col).value = element.value;
    row.getCell(col).alignment = { horizontal: 'left' }; //aligment
    row.getCell(col).border = borderStyles;//border styles
}

//funcio per printar arrays al excel
export function handleArrayAttribute(element, cursor) {
    const initObject = initializeFirstSheet(cursor);
    const row = initObject.row;
    let col = initObject.col;
    cursor = initObject.cursor;
    firstSheet.getColumn(col).width = 15;
    backgroundColor(row, col, 'C7C7C7');
    row.getCell(col).value = element.key;
    row.getCell(col).font = { bold: true }
    row.getCell(col).border = borderStyles;
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    if(element.value.length>3){
        Width = element.value.length * 10
    }
    firstSheet.getColumn(col).width = Width;
    row.getCell(col).value = element.value.join(", ");
    row.getCell(col).alignment = { horizontal: 'left' };
    row.getCell(col).border = borderStyles;
}

function initializeSecondSheet(cursor) {
    const fila = cursor.row
    const row = secondSheet.getRow(fila)
    const col = cursor.column;
    return { "fila": fila, "row": row, "col": col }
}

//printem les M i les P ordenadament
export function handleAttackData(element, cursor, position) {
    const initObject = initializeSecondSheet(cursor);
    let fila = initObject.fila;
    let col = initObject.col;
    const row = initObject.row;

    //recuperem els valors
    const m = element.objects[position][0].m
    const p = element.objects[position][0].iterations_values.length

    //l'escrivim al firstSheet
    const mFirstSheet = { key: "M", value: m }
    handleSimpleAttribute(mFirstSheet, firstCursor)

    //l'escrivim al firstSheet
    const pFirstSheet = { key: "P", value: p }
    handleSimpleAttribute(pFirstSheet, firstCursor)

    //Capçaleres
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "M"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "P"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    fila++;
    col = cursor.column

    //Printem les m, p
    for (let i = 1; i <= m; i++) {
        for (let j = 1; j <= p; j++) {
            const row = secondSheet.getRow(fila)
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).value = i
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).value = j
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            fila++;
            col = cursor.column
        }
    }
    cursor.column = String.fromCharCode(cursor.column.charCodeAt(0) + 2) //preparem el cursor per el seguent handle  
}

//printem els resultats de l'atac segons la m i la p
export function handleAttackResults(element, cursor, position) {
    const initObject = initializeSecondSheet(cursor);
    let fila = initObject.fila;
    let col = initObject.col;
    const row = initObject.row;
    secondSheet.getColumn(col).width = 16;//col width

    const attacked = element.objects[position];

    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "#elements"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    secondSheet.getColumn(col).width = 320;//col width
    backgroundColor(row, col, 'C7C7C7');
    row.getCell(col).border = borderStyles; //border styles
    row.getCell(col).font = { bold: true } //bold styles
    row.getCell(col).value = "attacked-elements"
    const attackedCol = col;
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    fila++;
    col = cursor.column
    let contador = 0;
    for (let i = 0; i < attacked.length; i++) { //m
        let elements = "";
        contador = 0;
        for (let j = 0; j < attacked[i].length; j++) {
            if (attacked[i][j] !== "") {
                contador += attacked[i][j].split(',').length;
                if (elements !== "") elements = elements + "," + attacked[i][j]
                else elements += attacked[i][j]
            }
            const row = secondSheet.getRow(fila)
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).value = contador;
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = elements;
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            fila++;
            col = cursor.column
        }
    }
    const width = (contador * 16) / 3;
    secondSheet.getColumn(attackedCol).width = width;//col width
    cursor.column = String.fromCharCode(cursor.column.charCodeAt(0) + 2)

}

export function handleMetricData(element, cursor, position,JsonFileExperiment,metricsMap) {
    const initObject = initializeSecondSheet(cursor);
    let col = initObject.col;
    const row = initObject.row;

    //recuperem els valors
    METRICS = element.objects[position];

    //Capçaleres
    for (const element of METRICS) {
        headerStyle(row, col, "C7C7C7")
        row.getCell(col).value = metricsMap[element.id] || element.id;
        col = String.fromCharCode(col.charCodeAt(0) + 1);
    }
    cursor.row++;
}

export function handleMetricValue(element, cursor, position) {
    let fila = cursor.row
    let col = cursor.column;

    const metricsResults = element.objects[position];
    const nMetriques = metricsResults[0][0].length;

    for (let i = 0; i < metricsResults.length; i++) {//5
        for (let j = 0; j < metricsResults[i].length; j++) {//30
            for (let z = 0; z < metricsResults[i][j].length; z++) {//9
                const row = secondSheet.getRow(fila);
                row.getCell(col).border = borderStyles; //border styles
                row.getCell(col).alignment = { horizontal: 'center' }; //aligment
                row.getCell(col).value = getValue(metricsResults[i][j][z])
                col = String.fromCharCode(col.charCodeAt(0) + 1);
            }
            col = cursor.column
            fila++

        }
    }

    //Deixem el cursor preparat per els atacs o les estadistiques
    cursor.row--;
    cursor.column = String.fromCharCode(cursor.column.charCodeAt(0) + nMetriques)
}

export function handleELCC(element, cursor, position, JsonFileExperiment,metricsMap,networksMap){
    const initObject = initializeSecondSheet(cursor);
    let col = initObject.col;
    let fila = initObject.fila;

    const ObjectsName = {
        networks:networks,
        percentage:handleLCCresults
    }

    const dictionaryKeys = Object.keys(ObjectsName)

    for (let i = 0; i < dictionaryKeys.length; i++) {
        const name = dictionaryKeys[i];
        for (let j = 0; j < JsonFileExperiment.objects.length; j++) {
            if (JsonFileExperiment.objects[j].name  === name) {
                const funcio = ObjectsName[name]
                funcio(element,cursor,position,j,col,fila,networksMap)
                break
            }
        }
    }
}

function networks(element,cursor,position,x,col,fila,networksMap){
    nNetworks=element.objects[x].length;
    for(let i = 0;i < nNetworks; i++){
        NETWORKS=element.objects[x];
    }
}

function networksHeader(col,fila,networksMap){
    for(let i = 0;i < nNetworks; i++){
        col = String.fromCharCode(col.charCodeAt(0) + 1);
        const row = secondSheet.getRow(fila)
        headerStyle(row, col, 'C7C7C7')
        secondSheet.getColumn(col).width = 15;//col width
        row.getCell(col).value = networksMap[NETWORKS[i]] || NETWORKS[i];
    }
}

function handleLCCresults(element,cursor,position,x,col,fila,networksMap){
    fila=handleElccValue(element,cursor,position,x,col,fila,networksMap)
    fila= handleLwAttr(element,cursor,position,x,col,fila,networksMap)
    handleUpAttr(element,cursor,position,x,col,fila,networksMap)
}

function handleElccValue(element,cursor,position,x,col,fila,networksMap){
    secondSheet.getColumn(col).width = 15;//col width
    const row = secondSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "eLCC"
    fila+=2;

    networksHeader(col,fila,networksMap)
    fila++;
    for(let i = 0; i<element.objects[x].length;i++){
        const row=secondSheet.getRow(fila);
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i+1;
        for(let j=0;j<nNetworks;j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][j][i].elcc)
 
        }
        col=cursor.column
        fila++
    }
    return fila
}

function handleLwAttr(element,cursor,position,x,col,fila,networksMap){
    fila+=3
    secondSheet.getColumn(col).width = 15;//col width
    const row = secondSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "LW_ATTR"
    fila+=2;

    networksHeader(col,fila,networksMap)
    fila++;
    for(let i = 0; i<element.objects[x].length;i++){
        const row=secondSheet.getRow(fila);
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i+1;
        for(let j=0;j<nNetworks;j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][j][i].lw_attr)
 
        }
        col=cursor.column
        fila++
    }
    return fila
}

function handleUpAttr(element,cursor,position,x,col,fila,networksMap){
    fila+=3
    secondSheet.getColumn(col).width = 15;//col width
    const row = secondSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "UP_ATTR"
    fila+=2;

    networksHeader(col,fila,networksMap)
    fila++;
    for(let i = 0; i<element.objects[x].length;i++){
        const row=secondSheet.getRow(fila);
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i+1;
        for(let j=0;j<nNetworks;j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][j][i].up_attr)
 
        }
        col=cursor.column
        fila++
    }
}



export function handleOptionalComputations(element, cursor, position, JsonFileExperiment,metricsMap) {

    const ObjectsName = {
        statistics: handleStatistics,
        pca: handlePCA,
        robustness: handleRobustness,
    }

    const dictionaryKeys = Object.keys(ObjectsName)
    const optionalOptions = element.objects[position];

    let keyArray = []
    for (let i = 0; i < optionalOptions.length; i++) {
        const name = Object.keys(optionalOptions[i]);
        keyArray=name
    }

    for (let i = 0; i < dictionaryKeys.length; i++) {
        if (keyArray.includes(dictionaryKeys[i])) {
            const funcio = ObjectsName[dictionaryKeys[i]]
            funcio(element, position,JsonFileExperiment,metricsMap)
        }
    }
}

function handlePCA(element, position,JsonFileExperiment,metricsMap){
    let value = false;
    if (element.objects[position][0].pca) value = true
    const pca = { key: "PCA", value: value.toString() }
    handleSimpleAttribute(pca, firstCursor)

    if(value){
        const jsonArray = Object.keys(JsonFileExperiment).map(key => {
            const value = JsonFileExperiment[key]
            const newObject = { key, value }
            return newObject
        });

        let indexOfObjects
        for(let i=0; i<jsonArray.length;i++){
            if(jsonArray[i].key==="objects")indexOfObjects=i;
        }

        const objects = jsonArray[indexOfObjects].value

        const name = "pca_value";

        for (let i = 0; i < objects.length; i++) {
            if (objects[i].name === name) {
                handlePCASheet(element, i,metricsMap)
                break
            }
        }
    }
}

function handlePCASheet(element, position,metricsMap){
    const cursor = {
        row: 2,
        column: 'B'
    }
    const pcaSheet = workbook.addWorksheet('PCA Sheet');

    let fila = cursor.row
    let col = cursor.column;

    fila=handlePOV(fila,col,pcaSheet,element,position)
    handleWeights(fila,col,pcaSheet,cursor,element,position,metricsMap)
}

function handlePOV(fila,col,pcaSheet,element,position){
    pcaSheet.getColumn(col).width = 15;//col width
    const row = pcaSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "POV"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    row.getCell(col).alignment = { horizontal: 'center' }; //aligment
    row.getCell(col).border = borderStyles; //border styles
    row.getCell(col).value = getValue(element.objects[position][0].pov)
    return fila+=3
}

function handleWeights(fila,col,pcaSheet,cursor,element,position,metricsMap){
    metricsHeader(METRICS.length,col,pcaSheet,fila,metricsMap)
    col=cursor.column
    fila++
    pcaSheet.getColumn(col).width = 15;//col width
    const row = pcaSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "WEIGHTS"
    for(let i=0; i<element.objects[position][0].weights.length;i++){
        col = String.fromCharCode(col.charCodeAt(0) + 1);
        row.getCell(col).alignment = { horizontal: 'center' }; //aligment
        row.getCell(col).border = borderStyles; //border styles
        row.getCell(col).value = getValue(element.objects[position][0].weights[i])
    }
}

function handleRobustness(element, position,JsonFileExperiment,metricsMap){
    let value = false;
    if (element.objects[position][0].robustness) value = true
    const robustness = { key: "Robustness", value: value.toString() }
    handleSimpleAttribute(robustness, firstCursor)

    if(value){
        const jsonArray = Object.keys(JsonFileExperiment).map(key => {
            const value = JsonFileExperiment[key]
            const newObject = { key, value }
            return newObject
        });

        let indexOfObjects
        for(let i=0; i<jsonArray.length;i++){
            if(jsonArray[i].key==="objects")indexOfObjects=i;
        }

        const objects = jsonArray[indexOfObjects].value

        const name = "robustness_value";

        for (let i = 0; i < objects.length; i++) {
            if (objects[i].name === name) {
                handleRobustnessSheet(element, i,metricsMap)
                break
            }
        }
    }
}

function handleRobustnessSheet(element, position,metricsMap){
    const cursor = {
        row: 2,
        column: 'B'
    }
    const robustnessSheet = workbook.addWorksheet('Robustness Sheet');

    let fila = cursor.row
    let col = cursor.column;

    fila=handleMeanRobustness(fila,col,robustnessSheet,cursor,element,position,metricsMap)
    fila=handleNormalizedWeights(fila,col,robustnessSheet,cursor,element,position,metricsMap)
    handleRobustnessValues(fila,col,robustnessSheet,cursor,element,position,metricsMap)

}

function handleMeanRobustness(fila,col,robustnessSheet,cursor,element,position,metricsMap){
    robustnessSheet.getColumn(col).width = 25;//col width
    const row = robustnessSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "MEAN"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    row.getCell(col).alignment = { horizontal: 'center' }; //aligment
    row.getCell(col).border = borderStyles; //border styles
    row.getCell(col).value = getValue(element.objects[position].mean_robustness)
    return fila+=3
}

function handleNormalizedWeights(fila,col,robustnessSheet,cursor,element,position,metricsMap){
    metricsHeader(METRICS.length,col,robustnessSheet,fila,metricsMap)
    col=cursor.column
    fila++
    robustnessSheet.getColumn(col).width = 25;//col width
    const row = robustnessSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "NORMALIZED WEIGHTS"
    for(let i=0; i<element.objects[position].normalized_weights.length;i++){
        col = String.fromCharCode(col.charCodeAt(0) + 1);
        row.getCell(col).alignment = { horizontal: 'center' }; //aligment
        row.getCell(col).border = borderStyles; //border styles
        row.getCell(col).value = getValue(element.objects[position].normalized_weights[i])
    }
    return fila+=3
}
function handleRobustnessValues(fila,col,robustnessSheet,cursor,element,position,metricsMap){
    let row = robustnessSheet.getRow(fila)
    const M = element.objects[position].robustness.length;

    row = robustnessSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "M"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "P"
    col = String.fromCharCode(col.charCodeAt(0) + 1);
    robustnessSheet.getColumn(col).width = 15;
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "ROBUSTNESS"

    for(let i = 0; i<M;i++){
        for(let j=0; j<element.objects[position].robustness[i].length;j++){
            fila++
            col=cursor.column
            const row = robustnessSheet.getRow(fila)
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = i+1
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = j+1
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position].robustness[i][j])
        }
    }
}

function handleStatistics(element, position,JsonFileExperiment,metricsMap) {
    let value = false;
    if (element.objects[position][0].statistics) value = true
    const statistics = { key: "Statistics", value: value.toString() }
    handleSimpleAttribute(statistics, firstCursor)

    if (value) {
        const jsonArray = Object.keys(JsonFileExperiment).map(key => {
            const value = JsonFileExperiment[key]
            const newObject = { key, value }
            return newObject
        });

        let indexOfObjects
        for(let i=0; i<jsonArray.length;i++){
            if(jsonArray[i].key==="objects")indexOfObjects=i;
        }

        const objects = jsonArray[indexOfObjects].value

        const name = "statistics_value";

        for (let i = 0; i < objects.length; i++) {
            if (objects[i].name === name) {
                handleStatisticsSheet(element, i,metricsMap)
                break
            }
        }
    }

}

function handleStatisticsSheet(element, position,metricsMap){
    const cursor = {
        row: 2,
        column: 'B'
    }
    const statisticsSheet = workbook.addWorksheet('Statistics Sheet'); //second excel sheet

    const p = element.objects[position].length;
    const nMetrics = element.objects[position][0].length;

    let fila = cursor.row
    let col = cursor.column;

    fila=handleMean(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap)
    fila=handleSD(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap)
    fila=handleMin(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap)
    handleMax(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap)
}

function handleMean(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap){
    statisticsSheet.getColumn(col).width = 15;//col width
    const row = statisticsSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "MEAN"

    fila+=2;
    metricsHeader(nMetrics,col,statisticsSheet,fila,metricsMap);
    fila++;
    for(let i = 0; i < p; i++){
        const row = statisticsSheet.getRow(fila)
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i + 1;
        for(let j = 0;j < nMetrics; j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][i][j].mean)
        }
        fila++
        col=cursor.column
    }
    return fila+=3
}

function handleSD(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap){
    statisticsSheet.getColumn(col).width = 15;//col width
    const row = statisticsSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "SD"

    fila+=2;
    metricsHeader(nMetrics,col,statisticsSheet,fila,metricsMap);
    fila++;
    for(let i = 0; i < p; i++){
        const row = statisticsSheet.getRow(fila)
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i + 1;
        for(let j = 0;j < nMetrics; j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][i][j].sd)
        }
        fila++
        col=cursor.column
    }
    return fila+=3
}

function handleMin(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap){
    statisticsSheet.getColumn(col).width = 15;//col width
    const row = statisticsSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "MIN"

    fila+=2;
    metricsHeader(nMetrics,col,statisticsSheet,fila,metricsMap);
    fila++;
    for(let i = 0; i < p; i++){
        const row = statisticsSheet.getRow(fila)
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i + 1;
        for(let j = 0;j < nMetrics; j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][i][j].min)
        }
        fila++
        col=cursor.column
    }
    return fila+=3
}

function handleMax(cursor,statisticsSheet,p,nMetrics,fila,col,element,position,metricsMap){
    statisticsSheet.getColumn(col).width = 15;//col width
    const row = statisticsSheet.getRow(fila)
    headerStyle(row, col, 'C7C7C7')
    row.getCell(col).value = "MAX"

    fila+=2;
    metricsHeader(nMetrics,col,statisticsSheet,fila,metricsMap);
    fila++;
    for(let i = 0; i < p; i++){
        const row = statisticsSheet.getRow(fila)
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = i + 1;
        for(let j = 0;j < nMetrics; j++){
            col = String.fromCharCode(col.charCodeAt(0) + 1);
            row.getCell(col).alignment = { horizontal: 'center' }; //aligment
            row.getCell(col).border = borderStyles; //border styles
            row.getCell(col).value = getValue(element.objects[position][i][j].max)
        }
        fila++
        col=cursor.column
    }
}

function metricsHeader(nMetrics,col,statisticsSheet,fila,metricsMap){
    for(let i = 0;i < nMetrics; i++){
        col = String.fromCharCode(col.charCodeAt(0) + 1);
        const row = statisticsSheet.getRow(fila)
        headerStyle(row, col, 'C7C7C7')
        row.getCell(col).value = metricsMap[METRICS[i].id ] || METRICS[i].id ;
    }
}

function getValue(element){
    if(element===undefined)return "NA"
    else{
        if (Number.isInteger(element) || typeof element === "string") return element;
        else return parseFloat(element.toFixed(4)) 
    }
}
