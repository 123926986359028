// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: Gerard Lozano, Pau Vilella.

// firstSheet.js file: This file contains the logic for parsing experiment general data to excel format.

import { handleSimpleAttribute, handleArrayAttribute } from './handlers' //import all the handler functions for this sheet

//this cursor will tell us where to write in the sheet
export let cursor =undefined

// first sheet generator
export const handleFirstSheet = (JsonFileData, JsonFileExperiment,networksMap,metricsMap) => {
    cursor={row: 6,column: 'B'}

    //Object with <key,value> components that identifies the objects that you can find on the excel
    //You can add as much objects you need in your sheet
    //Every pair needs to have their handler in the handlers file in order to write the value on the excel
    const ExperimentData = {
        status: handleSimpleAttribute,
        ex_time: handleSimpleAttribute,
        experiment: handleSimpleAttribute,
        current: handleSimpleAttribute,
        createdAt: handleSimpleAttribute,
        updatedAt: handleSimpleAttribute
    }

    //busquem l'id de la xarxa o de les xarxes
    const jsonExperimentArray = Object.keys(JsonFileData).map(key => {
        const value = JsonFileData[key]
        const newObject = { key, value }
        return newObject
    });

    const objects = jsonExperimentArray.find(element =>
        element.key === "objects"
    )
    //creem una clau valor i escrivim l'objecte al excel
    const networkName = objects.value[0].map(id=>networksMap[id] || id)
    const networks = { key: "networks", value: networkName }
    handleArrayAttribute(networks, cursor)

    //We get the keys from the Dictionary object in array format
    const dictionaryKeys = Object.keys(ExperimentData)

    //We convert the JSON to an array with <key,value> values
    const jsonArray = Object.keys(JsonFileData).map(key => {
        const value = JsonFileData[key]
        const newObject = { key, value }
        return newObject
    });

    //fem una cerca per comprovar quines claus del jsonArray coincideixen amb les claus del diccionari.
    //un cop es troba la clau, s'executa la funcio per afegir-la al excel
    for (const element of jsonArray) {
        const jsonKey = element.key
        if (dictionaryKeys.includes(jsonKey)) {
            const funcio = ExperimentData[jsonKey]
            funcio(element, cursor) //this is the function of every Dictionary pair
        }
    }

}