// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// metricsInfo.js file: File that contains network metrics information util calls.


// 1. DEPENDENCIES

// NRS API calls.
import {
    getMetricsInformation,
    getDefaultColumnMetrics
} from '../api';


// 2. FUNCTIONS

// Where networks metrics information is stored.
let metrics_info = [];

// Default column metrics.
let default_column_metrics = [];

// Function to get full metrics information from server.
export const getMetricsInfo = async () => {
    if(!metrics_info.length){
        const response = await getMetricsInformation();
        if(response.status !== 200) throw new Error('Problem fetching for metrics information.');
        metrics_info = response.json;
    }
    return JSON.parse(JSON.stringify(metrics_info));
}

// Function to get default column metrics information from server.
export const getDefaultMetrics = async () => {
    if(!default_column_metrics.length){
        const response = await getDefaultColumnMetrics();
        if(response.status !== 200) throw new Error('Problem fetching for default metric columns information.');
        default_column_metrics = response.json;
    }
    return JSON.parse(JSON.stringify(default_column_metrics));
}

// Returns the list of active metrics.
export const getActiveMetrics = async () => {
    if(!metrics_info.length){
        await getMetricsInfo();
    }
    return metrics_info.filter(metric => metric.active);
}

// Returns the list of the current NRS active metrics that the network 'network' does not have.
export const getNetworkMissingActiveMetrics = (network, metrics_info) => {

    // Store the active metrics.
    const active_metrics = metrics_info.filter(metric => metric.active);

    // If the network does not have metrics array initialized, return the whole active metrics
    if(network === undefined || network.data === undefined || network.data.metrics === undefined) return active_metrics;
    
    // Check if some active metric is missing in network.
    const missing_metrics = [];
    for(const active_metric of active_metrics){
        // If some active metric is missing in network, append the metric in 'missing_metrics'.
        if(network.data.metrics[active_metric.id] === undefined || network.data.metrics[active_metric.id] === null || network.data.metrics[active_metric.id] === 'NA'){
            missing_metrics.push(active_metric);
        }
    }
    return missing_metrics;
}

// Returns the list of the current NRS metrics from the network that are being computed.
export const getNetworkComputingMetrics = (network, metrics_info) => {

    // If the network does not have metrics array initialized, return an empty array
    if(network === undefined || network.data === undefined || !network.data.pending_metrics) return [];
    
    // Check for pending metrics in network data. Populate it with metric info object.
    const computing_metrics = [];
    for(const computing_metric of network.data.pending_metrics){
        const metric = metrics_info.find(metric => metric.id === computing_metric);
        if(metric) computing_metrics.push(metric);
    }
    return computing_metrics;
}

// Function to restore default column metrics visualization configuration.
export const getAndSetDefaultUserColumnMetrics = async () => {
    const column_metrics = await getDefaultMetrics();
    localStorage.setItem("column_metrics_visualization", column_metrics.join(","));
    return column_metrics;
}

// Function to users default metric columns visualization configuration.
export const getUserColumnMetrics = async () => {
    try{
        // Check if custom column metrics visualization is configured in device. If not, perform an API call to get the default column metrics.
        const column_metrics = localStorage.getItem("column_metrics_visualization").split(",");
        if(!column_metrics) throw new Error();
        return column_metrics;
    } catch(error) {
        // Some error ocurred parsing the current configuration, make API call to get the default one.
        return await getAndSetDefaultUserColumnMetrics();
    }
    
}

// Function to set users default metric columns visualization configuration.
export const setUserColumnMetrics = (column_metrics) => {
    localStorage.setItem("column_metrics_visualization", column_metrics);
}