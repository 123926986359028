// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// objectFunctions.js file: Includes util object functions to be used. It centralizes
//                          all the logic for object component management.

// NRS util functions.
import { getActiveMetrics } from '../../../utils/metricsInfo';
import { filterObject } from '../../../utils/utilFunctions';

// Function that checks if an input object is filled.
//  It receives the 'object_name' of the object to evaluate.
//  Also receives all the object_values of all the experiment selected objects, the 'object_values'.
//  'object_values[object_name]' contains the object value of the 'object_name'.
//  We have all the object values information as some optional objects validity depend on the value of other objects.
export const inputObjectFilled = (object_name, object_values) => {

    const object_value = object_values[object_name];
    let toast_message = undefined;
    let ret_value = false;

    if(object_name === 'networks'){
        const workspaces_with_content = filterObject(object_value, ([property, value]) => value.length);
        ret_value = Object.keys(workspaces_with_content).length > 0;
    }
    else if(object_name === 'metric_data'){
        ret_value = object_value && object_value.length;
    }
    else if(object_name === 'attack_data'){
        if(!object_value || !object_value.length) ret_value = false;
        else ret_value = true;
    }
    else if(object_name === 'optional_computations') {
        if (typeof object_value.statistics === 'boolean' && typeof object_value.pca === 'boolean' && typeof object_value.robustness === 'boolean'){
            const metric_data_object = object_values['metric_data'] || [];
            const array_metrics = metric_data_object.flatMap(metric => metric.vector ? [metric.code] : []);
            if(object_value.statistics === true && metric_data_object && array_metrics.length){
                ret_value = false;
                toast_message = "The metrics "+array_metrics.join(', ')+" are vectors so the statistics can not be computed.";
            }
            else if (object_value.pca === false && object_value.robustness === true) {
                ret_value = false;
                toast_message = "To compute robustness you have to select compute PCA too";
            }
            else if (object_value.pca === true && metric_data_object && array_metrics.length) {
                ret_value = false;
                toast_message = "The metrics "+array_metrics.join(', ')+" are vectors so the PCA can not be computed.";
            }
            else ret_value = true;
        }
        else ret_value = false;
    }
    else if (object_name === 'percentage') {
        if(!object_value || !object_value.length) ret_value = false;
        else ret_value = true;
    }
    else if (object_name === 'robustness-ai_data'){
        ret_value = object_value.completed;
    }
    else {
        ret_value = false;
        toast_message = "Unknown object name.";
    };

    return { ok: ret_value, toast_message: toast_message}
}

// Object population function, so it takes the 'name' (and optionally the 'ex_scope') in order to
//  obtain an string that will be shown on the stepper as object description.
const populateObject = (object) => {
    // Depending on the object name and its scope get the step text and append it to 'object'.
    if (object.name === 'networks') {
        if (object.ex_scope === 1) object.step = 'Select Networks (Set)';
        else object.step = 'Select Network (1)';
    } else if (object.name === 'metric_data') {
        if (object.ex_scope === 1) object.step = 'Select Metrics (Set)';
        else object.step = 'Select Metric (1)';
    } else if (object.name === 'attack_data') {
        if (object.ex_scope === 1) object.step = 'Select Attacks (Set)';
        else object.step = 'Select Attack (1)';
    } else if (object.name === 'optional_computations') {
        object.step = 'Select Optional Computations';
    } else if (object.name === 'percentage') {
        object.step = 'Select Percentages (Set)';
    } else if (object.name === 'robustness-ai_data') {
        object.step = 'Select AI Data';
    } else object.step = 'Default Text: '+object.name;

    return object;
}

// Function that obtains the experiment data object from an object_value object. 
//  Sometimes, we store the objects 'object_value' on the spawner in an optimized way, so they format may differ
//  with the one that the server expects on POST. For that reason, this function receives an object name 
//  'input_object_name' and its value 'object_value', and finally returns the object that the server expects.
const getExperimentDataObject = (object_value, input_object_name) => {
    const data_object = [];
    if (input_object_name === 'networks') {
        // 'object_value' is a object with a container ID as property and networks as value.
        for (const [, value] of Object.entries(object_value)) {
            // Get the networks and push them to the data object. 'value' is the network array for each container.
            for (const network of value) {
                data_object.push(network._id);
            }
        }
    } else if (input_object_name === 'attack_data') {
        // 'object_value' is an array with introduced attack_data.
        return object_value;
    } else if (input_object_name === 'metric_data') {
        // 'object_value' is an array with introduced attack_data.
        for (const metric of object_value) {
            data_object.push({
                id: metric.id
            });
        }
    } else if (input_object_name === 'optional_computations') { 
        return [object_value];
    } else if (input_object_name === 'percentage') { 
        return object_value;
    } else if (input_object_name === 'robustness-ai_data') { 
        return [object_value];
    } else throw new Error('Undefined input object');
    return data_object;
}

// Function that renders network objects with its 'ID' populated by its name that has to be in 'network_values'
//  structure (see ExperimentsObjectsSummary.js).
const renderObjectsNetworks = (objects, network_values) => {
    const all_networks = Object.values(network_values).flat(Infinity);
    return all_networks.flatMap(network => objects.includes(network._id) ? [network.name] : []).join(', ');
}

// Function that renders attack_data objects with its info populated by its text.
const renderObjectsAttackData = (objects) => {
    return objects[0].code;
}

// Function that renders metric_data objects with its info populated by its text.
const renderObjectsMetricData = (objects, metrics_info) => {

    const metric_codes = [];
    for (const metric_data_object of objects) {
        const metric_id = metric_data_object.id;
        const metric_code = metrics_info.find(metric => metric.id === metric_id).code;
        metric_codes.push(metric_code);
    }
    return metric_codes.join(', ');
}

// Function that renders metric_data objects with its info populated by its text.
const renderObjectsOptionalData = (objects, object_values) => {
    let message = 'None';
    
    if (object_values.optional_computations.statistics) message = 'Statistics';
    
    if (message === 'None' && object_values.optional_computations.pca) message = 'PCA';
    else if (object_values.optional_computations.pca) message = message + ', PCA';

    if (message === 'None' && object_values.optional_computations.robustness) message = 'R*';
    else if (object_values.optional_computations.robustness) message = message + ', R*';
    
    return message;
}

// Function that renders metric_data objects with its info populated by its text.
const renderObjectsPercentage = (objects, object_values) => {
    const message = "From " + objects[0] + " to " + objects[objects.length-1];
    return message;
}

// Function that renders objects depending on the object name and its value.
//  - Add here the function that handles the column render of the object, it has to be an string.
const renderObjects = (object_name, objects, object_values, metrics_info) => {
    if (object_name === 'networks') return renderObjectsNetworks(objects, object_values.networks);
    else if (object_name === 'attack_data') return renderObjectsAttackData(objects);
    else if (object_name === 'metric_data') return renderObjectsMetricData(objects, metrics_info);
    else if (object_name === 'optional_computations') return renderObjectsOptionalData(objects, object_values);
    else if (object_name === 'percentage') return renderObjectsPercentage(objects, object_values);
    else if (object_name === 'robustness-ai_data') return object_values['robustness-ai_data'].types.join(', ');
    else return 'error';
}

// *****************************************************************************
// ******** STATIC FUNCTIONS (NO NEED TO BE CHANGED ON OBJECT ADDITION) ********
// *****************************************************************************

// Function to obtain the experiment type input objects, used to know which objects the experiment need to ask for.
//  - NO NEED TO TOUCH THIS FUNCTION. On object addition, only touch 'populateObject'.
export const getExperimentTypeInputObjects = (experiment_type) => {

    // Get all input objects.
    const input_objects = experiment_type.objects.flatMap(object => object.input ? [populateObject(object)] : []);
    return input_objects;

}

// Function that obtains the experiment data object array from the objectValues structure.
//  This is to prepare the data for the API submit.
//  - NO NEED TO TOUCH THIS FUNCTION. On object addition, only touch 'getExperimentDataObject'.
export const getExperimentDataObjects = (object_values, input_objects) => {

    // For each input object defined, get the object value and transform it into the API format (check the documentation).
    const experiment_data_objects = [];
    for (const input_object of input_objects) {
        const object_value = object_values[input_object.name];
        experiment_data_objects.push(getExperimentDataObject(object_value, input_object.name))
    }
    return experiment_data_objects;
}

// Set input object columns on an experiment type simulation table.
//  - NO NEED TO TOUCH THIS FUNCTION. On object addition, only touch 'renderObjects'.
export const getInputObjectColumns = async (input_objects, object_values) => {
    const simulation_columns = [];

    // Get the active metrics information.
    const metrics_info = await getActiveMetrics();
    
    for (let counter = 0; counter < input_objects.length; counter++) {
        const input_object = input_objects[counter]
        simulation_columns.push({
            title: input_object.name,
            render: rowData => renderObjects(input_object.name, rowData.objects[counter], object_values, metrics_info)
        });
    }

    return simulation_columns;
}