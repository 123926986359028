// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// menu.js file: Contains the main APP menu data.

// The menu is formed by:
//   Home:        Main APP page, contains selected workspace information & mode util things.
//   Experiments: Experiments Manager, to spawn new experiments or see results.
//   Networks:    To manage workspaces and networks, visualize, etc.
const Menu = [{
    label: "Home",
    pathname: "/"
  },
  {
    label: "Networks",
    pathname: "/networks"
  },
  {
    label: "Experiments",
    pathname: "/experiments"
  }
];

// Export the menu.
export default Menu;