// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// api.js file: API Routes middleware.

// URI of server
const uri = 'api/';

// Default settings.
let def_settings = {
    credentials: 'include',
    headers: {
        'nrs-session-token': ''
    }
}

// Private function to refresh the session token in case of having a valid token refresh cookie.
const tryRefresh = async () => {
    const res = await fetch(uri + 'auth/refresh_session_token', def_settings);
    if (res.status === 200) {
        // Get and store in memory token.
        const data = await res.json();
        def_settings.headers['nrs-session-token'] = data.token;
        return true;
    } else return false;
}

// Private function to perform an API call, and re-try it after a 'tryRefresh' in case of session token expiration.
//  Returns an object with fields:
//      status: API call response status.
//      json: If status is 200, then json contains the JSON body (if any).
const performCallAPI = async (call_uri, settings) => {
    // Perform API call (1st attempt).
    let res = await fetch(call_uri, settings);
    // Check if it returned a 403 UNAUTHORIZED status.
    if (res.status === 403) {
        // If so, try for a token refresh.
        if (await tryRefresh()) {
            // Token refreshed, perform API call again with the new session token (2nd attempt).
            settings.headers['nrs-session-token'] = def_settings.headers['nrs-session-token'];
            res = await fetch(call_uri, settings);
        }
    }
    // If call failed, return only status object.
    if (res.status !== 200) return {
        status: res.status
    };
    // Call success, check if it returned a JSON body.
    try {
        // Parse response as text.
        const text = await res.text();
        // Try to parse text as JSON (if not JSON, it will throw an error).
        const json = JSON.parse(text);
        // Response data is in JSON format, return it with the status code.
        return {
            status: res.status,
            json: json
        }
    } catch (error) {
        // No JSON data returned, return only status code.
        return {
            status: res.status
        };
    }
}

// Function to create a custom settings object.
const createSettings = (req_type, body_data, req_body_type) => {
    // Prepare and return API Call settings.
    let settings = {
        method: req_type,
        ...JSON.parse(JSON.stringify(def_settings))
    };
    // Add headers to specify the content of the request body type.
    if (req_body_type === 'json') {
        // Add body_data (if provided).
        if(body_data) settings.body = JSON.stringify(body_data);
        settings.headers.Accept = 'application/json';
        settings.headers['Content-Type'] = 'application/json';
    }
    else if (req_body_type === 'file') {
        // Add body_data (if provided).
        const files = body_data;
        if(files) settings.body = files;
    }
    return settings;
}

// API CALLS
//  Returns an object with response information: { status, json }

// SESSION CALLS

// This function gets logged user information.
export const getLoggedUser = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'auth/me', def_settings);
}

// This function gets the login social options from server.
export const getSocialOptions = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'auth/social_options', def_settings);
}

// This function tries to login an user.
export const tryLogin = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'auth/', settings);
}

// Perform logout.
export const logout = async () => {
    // Perform API call and return the response.
    const response_data = await performCallAPI(uri + 'auth/logout', def_settings);
    // If API call success, then remove the session token (the server has removed the refresh token cookie in logout call).
    def_settings.headers['nrs-session-token'] = '';
    return response_data;
}

// WORKSPACE CALLS.

// Get logged user available workspaces.
export const getUserWorkspaces = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/mine', def_settings);
}

// Get all APP consortium workspaces (admin call).
export const getConsortiumWorkspaces = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/consortium', def_settings);
}

// Select logged user workspace.
export const selectWorkspace = async (workspace_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/select', def_settings);
}

// Create a new consortium workspace (admin call).
export const createConsortiumWorkspace = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/consortium', settings);
}

// Modify a consortium workspace (admin call).
export const modifyConsortiumWorkspace = async (workspace_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('PUT', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/consortium/' + workspace_id, settings);
}

// Delete a consortium workspace (admin call).
export const deleteConsortiumWorkspace = async (workspace_id) => {
    // Prepare API call settings.
    const settings = createSettings('DELETE');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/consortium/' + workspace_id, settings);
}

// USERS CALLS

// Get all users (admin call).
export const getAllUsers = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'users/', def_settings);
}

// Modify specified user.
export const updateUser = async (user_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('PUT', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'users/' + user_id, settings);
}

// Delete specified user.
export const deleteUser = async (user_id) => {
    // Prepare API call settings.
    const settings = createSettings('DELETE');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'users/' + user_id, settings);
}

// METRICS CALLS

// Get metrics information.
export const getMetricsInformation = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'metrics/', def_settings);
}

// Get default columns metrics visualization for network tables.
export const getDefaultColumnMetrics = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'metrics/def_columns', def_settings);
}

// GENERATOR CALLS.

// Get generators and parameters information.
export const getGeneratorInformation = async() => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'generator/config', def_settings);
}

// Generate a new graph.
export const generateGraph = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'generator/', settings);
}

// Get a generator task state.
export const getGeneratorTaskState = async (task_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'generator/tasks/' + task_id, def_settings);
}

// NETWORK CONTAINERS CALLS.

// Get all APP containers information (can be filtered by type field 'type') (admin call).
export const getContainers = async (type) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers?type='+type, def_settings);
}

// Get a container information (user must have reading permissions on it).
export const getContainer = async (container_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id, def_settings);
}

// Get logged user accessible containers information.
export const getAccessibleContainers = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/accessible', def_settings);
}

// Creates a new remote container (admin call).
export const createRemoteContainer = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/remote', settings);
}

// Updates remote container networks (refetch) (admin call).
export const updateRemoteContainer = async (container_id) => {
    // Prepare API call settings.
    const settings = createSettings('PUT', {}, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/remote/' + container_id + '/refetch', settings);
}

// Deletes a remote container (admin call).
export const deleteRemoteContainer = async (container_id) => {
    // Prepare API call settings.
    const settings = createSettings('DELETE');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/remote/' + container_id, settings);
}

// Generates a new output format for the network.
export const convertNetwork = async (container_id, network_id, format) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/' + network_id + '/convert/' + format, def_settings);
}

// CONTAINER NETWORKS ROUTES.

// Get all networks for an specific container.
export const getContainerNetworks = async (container_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks', def_settings);
}

// Get a network for an specific container.
export const getContainerNetwork = async (container_id, network_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/' + network_id, def_settings);
}

// Uploads an array of networks to a container.
export const uploadContainerNetworks = async (container_id, files, format) => {
    // Prepare API call settings.
    const settings = createSettings('POST', files, 'file');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks?format='+format, settings);
}

// Uploads an array of networks to a container (combined).
export const uploadContainerNetworksCombined = async (container_id, files, format) => {
    // Prepare API call settings.
    const settings = createSettings('POST', files, 'file');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks?format='+format+'&combined=true', settings);
}

// Modifies a container network.
export const modifyContainerNetwork = async (container_id, network_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('PUT', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/' +network_id, settings);
}

// Deletes a container network.
export const deleteContainerNetwork = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('DELETE', {}, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + data.container_id + '/networks/' +data.network_id, settings);
}

// Import networks (array of IDs specified in data) to a container.
export const importToContainer = async (container_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/import', settings);
}

// NETWORK VISUALIZATION DATA ROUTES.

// Get visualization data for an specific network in a container.
export const getNetworkVisualizationData = async (container_id, network_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/' + network_id + '/visualization', def_settings);
}

// Posts a new visualization data for an specific network in a container.
export const postNetworkVisualizationData = async (container_id, network_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/' + network_id + '/visualization', settings);
}

// Store visualization for container.
export const postContainerVisualizationCustomization = async (container_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/visualizer_customization', settings);
}

// Re-spawn a network metrics computation.
export const spawnMetricsComputation = async (container_id, network_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'containers/' + container_id + '/networks/' + network_id + '/refresh_metrics', def_settings);
}

// EXPERIMENT CALLS

// Get all experiment types information.
export const getExperimentTypes = async () => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'experiments/info', def_settings);
}

// Get all experiments of a workspace
export const getWorkspaceExperiments = async (workspace_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments', def_settings);
}

// Get the experiments of a workspace that matches the specified IDs in the body.
export const getWorkspaceExperimentsByIds = async (workspace_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments/getById', settings);
}

// Get an experiment source objectValues.
export const getExperimentSourceObjectValues = async (workspace_id, experiment_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments/' + experiment_id + '/source', def_settings);
}

// Get all simulations of an experiment
export const getExperimentSimulations = async (workspace_id, experiment_id) => {
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments/' + experiment_id + '/simulations', def_settings);
}

// Get the simulations of an experiment that matches the specified IDs in the body.
export const getExperimentSimulationsByIds = async (workspace_id, experiment_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments/' + experiment_id + '/simulations/getById', settings);
}

// Spawn an experiment.
export const createExperiment = async (workspace_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments', settings);
}

// Re-compute an existing experiment into a new one.
export const copyExperiment = async (workspace_id, experiment_id, data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + workspace_id + '/experiments/' + experiment_id + '/copy', settings);
}

// Function to get the number of simulations that an experiment would spawn.
export const getNumberOfSimulations = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('POST', data, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'experiments/sim_number', settings);
}

// Deletes a workspace experiment.
export const deleteWorkspaceExperiment = async (data) => {
    // Prepare API call settings.
    const settings = createSettings('DELETE', {}, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'workspaces/' + data.workspace_id + '/experiments/' +data.experiment_id, settings);
}

// Get all experiment types information.
export const moveExperimentToWorkspace = async (experiment_id, workspace_id) => {
    // Prepare API call settings.
    const settings = createSettings('POST', {}, 'json');
    // Perform API call and return the response.
    return await performCallAPI(uri + 'experiments/' + experiment_id + '/move/' + workspace_id, settings);
}