// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// OptionalMetricsComputationSelect.js file: Contains the optional metrics computation such as statistics, pca or robustness.


// 1. DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

// 2. FUNCTIONS

// NRS Web React OptionalMetricComputationsSelect Component.
//  OptionalMetricComputationsSelect component: An optional metric computations selector.
const OptionalMetricComputationsSelect = (props) => {

    // Props: 
    //  ex_scope:            Scope of the object {0 = 1 element, 1 = array} (mandatory).
    //  setObjectValue:      Function to call from parent when the object value changes (mandatory). This is called when is a change in selection.
    //  pre_value:           Initial value for objectValue (optional).
    const {
        setObjectValue,
        pre_value
    } = props;

    const [statistics, setStatistics] = useState(pre_value ? pre_value.statistics : false);
    const [pca, setPCA] = useState(pre_value ? pre_value.pca : false);
    const [robustness, setRobustness] = useState(pre_value ? pre_value.robustness : false);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === "statistics") {
            setStatistics(checked);
        }        
        else if (name === "pca") {
            setPCA(checked);
        }
        else if (name === "robustness") {
            setRobustness(checked);
        }
    };

    // Effect that gets the selected attack data on change and transmit them to the parent.
    useEffect(() => {
        
        const optional_computations = {
            "statistics": statistics,
            "pca": pca,
            "robustness": robustness
        };
        setObjectValue(optional_computations);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statistics, pca, robustness]);

    return (
        <FormControl component="fieldset">
            <FormGroup>
                <FormControlLabel control={
                    <Checkbox
                        checked={statistics}
                        onChange={handleCheckboxChange}
                        name="statistics"
                    />}
                    label="Compute statistics" 
                />
                <FormControlLabel control={
                    <Checkbox
                        checked={pca}
                        onChange={handleCheckboxChange}
                        name="pca"
                    />}
                    label="Compute PCA" 
                />
                <FormControlLabel control={
                    <Checkbox
                        checked={robustness}
                        onChange={handleCheckboxChange}
                        name="robustness"
                    />}
                    label="Compute R*" 
                />
            </FormGroup>
        </FormControl>
    );
}

// Export the component.
export default OptionalMetricComputationsSelect;