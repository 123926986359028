// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: Gerard Lozano, Pau Vilella.

// excel.js file: Contains the main logic for excel generation.


// 1. DEPENDENCIES

// NPM community 'file-saver' package: FileSaver.js is the solution to saving files on the client-side.
import saveAs from "file-saver";
import ExcelJS from "exceljs";

import { handleFirstSheet } from './firstSheet'
import { handleSecondSheet } from './secondSheet';

export let workbook=undefined //Excel workbook
export let firstSheet=undefined //first excel sheet
export let secondSheet=undefined //second excel sheet


const saveExcel = (fileName) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), fileName + ".xlsx");
    });
}

// Function that handles the sheet creation.
export const handleSheets = (JsonFileData, JsonFileExperiment, fileName, networksMap, metricsMap) => {
    workbook = new ExcelJS.Workbook();
    firstSheet=workbook.addWorksheet('First Sheet');
    secondSheet=workbook.addWorksheet('Second Sheet');
    handleFirstSheet(JsonFileData, JsonFileExperiment,networksMap,metricsMap) //write the first sheet
    handleSecondSheet(JsonFileData, JsonFileExperiment,networksMap,metricsMap) //write the second sheet
    saveExcel(fileName) //Download the excel
}
