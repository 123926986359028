// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// SocialIcons.js file: Contains the social icons object to be accessed from multiple components.

// NRS Social Options icons.
import linkedinIcon from '../../images/social_icons/linkedin.png';
import googleIcon from '../../images/social_icons/google.png';
import facebookIcon from '../../images/social_icons/facebook.png';
import githubIcon from '../../images/social_icons/github.png';

// Add the icons to the object.
const SocialIcons = {
    linkedin: linkedinIcon,
    google: googleIcon,
    facebook: facebookIcon,
    github: githubIcon
}

// Export the object.
export default SocialIcons; 