// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: Gerard Lozano, Pau Vilella.

// secondSheet.js file: This file contains the logic for parsing experiment result objects.

import { handleAttackData, handleAttackResults, handleMetricData, handleMetricValue, handleSimpleAttribute, handleOptionalComputations,handleELCC } from "./handlers";
import { cursor as firstCursor } from "./firstSheet"

//Generem l'excel de la segona pàgina
export const handleSecondSheet = (JsonFileData, JsonFileExperiment,networksMap,metricsMap) => {
    const cursor = {
        row: 2,
        column: 'B'
    }
    //Diccionari de parelles <clau,funcio> que determina els objectes que poden haver a l'excel
    const ObjectsName = {
        attack_data: handleAttackData,
        metric_data: handleMetricData,
        metric_value: handleMetricValue,
        elcc_value: handleELCC,
        attack_results: handleAttackResults,
        optional_computations: handleOptionalComputations,
    }
    //obtenir les claus del Dictionary en format array
    const dictionaryKeys = Object.keys(ObjectsName)
    //Convertim el JSON en un array amb parelles <clau,valor>
    const jsonArray = Object.keys(JsonFileExperiment).map(key => {
        const value=JsonFileExperiment[key]
        const newObject = { key, value }
        return newObject
    });

    let indexOfName,indexOfObjects
    for(let i=0; i<jsonArray.length;i++){
        if(jsonArray[i].key==="name")indexOfName=i;
        else if(jsonArray[i].key==="objects")indexOfObjects=i;
    }
    const SimulationName = { key: "Simulation type", value: jsonArray[indexOfName].value }
    handleSimpleAttribute(SimulationName, firstCursor)
    const objects = jsonArray[indexOfObjects].value

    for (let i = 0; i < dictionaryKeys.length; i++) {
        const name = dictionaryKeys[i];
        for (let j = 0; j < objects.length; j++) {
            if (objects[j].name === name) {
                const funcio = ObjectsName[name]
                funcio(JsonFileData, cursor, j, JsonFileExperiment,metricsMap,networksMap)
                break
            }
        }
    }
}