// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: Sergi Bergillos

// bottomMenu.js file: Contains the bottom APP menu data.

// The menu is formed by:
//   About us: Contains information about the BCDS research group.
//   Privacty policy: Contains the privacy policy from the APP.
//   Contact us: Contains the information to contact us.
const BottomMenu = [{
    label: "About us",
    pathname: "/about"
  },{
    label: "Privacy policy",
    pathname: "/privacy"
  },
  {
    label: "Contact us",
    pathname: "/contact"
  }
];

// Export the bottom menu.
export default BottomMenu;