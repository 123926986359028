// nrs-web: Network Research Simulator Front End Interface
// Author: ©BCDS Research Group - Girona University.
//  Contributors: David Martínez

// adminMenu.js file: Contains the admin page navigation menu data.

// The admin menu is formed by:
//   Admin:       Contains the Users Manager, Workspaces Manager, and Repositories Manager.
//   Analytics:   Analytical usage data of NRS APP.
//   Experiments: Admin Experiments Manager.
const adminMenu = [{
    label: "Admin",
    pathname: "/admin"
  },
  {
    label: "Analytics",
    pathname: "/admin/analytics"
  },
  {
    label: "Experiments",
    pathname: "/admin/experiments"
  }
];

// Export the menu.
export default adminMenu;